import React, { useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { navigate ,useLocation} from "@reach/router"
import { InstantSearch, Configure, InfiniteHits } from 'react-instantsearch-dom'
import PropertyCard from '../components/PropertyCard/PropertyCard';
import SearchFilter from '../components/SearchFilter/SearchFilter';
import SeoHelmet from '../components/SeoHelmet/seo-helmet';
import { Helmet } from 'react-helmet'
import SearchAreaContent from '../components/SearchAreaContent/SearchAreaContent';
import { searchStateToUrl, urlToSearchState, propertySearchParams, propertyIntro, propertyContent, propertyMeta } from "../common/utils";

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_ADMIN_KEY);
const index_name = process.env.GATSBY_ALGOLIA_INDEX;
class SearchResultTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchState: urlToSearchState(index_name, typeof window !== 'undefined' ? window.location : ''),
            minPrice : 0,
            maxPrice : 0
        }
    }
    componentDidMount() {
        window.addEventListener('popstate', this.onPopState);
        setTimeout(()=>{
            this.setState({ 
                minPrice : document.getElementById("minPrivalVal").value,
                maxPrice : document.getElementById("maxPrivalVal").value
            });
        },1000)
       
    }

    onPopState = ({ state }) => {
        this.setState({
            searchState: state || {},
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) { 
            this.setState({ 
                searchState: urlToSearchState(index_name, this.props.location),
                minPrice : document.getElementById("minPrivalVal").value,
                maxPrice : document.getElementById("maxPrivalVal").value
              });
        }
    }

    onSearchStateChange = (searchState) => {
        clearTimeout(this.debouncedSetState);
        this.debouncedSetState = setTimeout(() => {
       
            navigate(searchStateToUrl(index_name, searchState, this.props.path))
        }, 500);
        this.setState({ searchState });
    };  
    render() {
        const { searchState } = this.state;

        const searchParams = propertySearchParams(searchState);
        const introText = propertyIntro(searchParams).replace("The Pearl Island, Doha","The Pearl Island").replace("Lusail City, Lusail","Lusail City").replace("Msheireb Downtown Doha, Doha","Msheireb Downtown").replace("Gewan Island, Doha","Gewan Island");
        const introContent = propertyContent(searchParams).replace("The Pearl Island, Doha","The Pearl Island").replace("Lusail City, Lusail","Lusail City").replace("Msheireb Downtown Doha, Doha","Msheireb Downtown").replace("Gewan Island, Doha","Gewan Island");
        const meta = propertyMeta(searchParams)
        
        // console.log('introoo', introContent, introText)

        const introText_Split = introText.split(" ");
        const introText_index = introText_Split.filter((item,index)=> introText_Split.indexOf(item) === index)
        const introText_h1 = introText_index.toString().replace(/[, ]+/g, " ").replace("For","for").trim();
         var searchurl = typeof window !== 'undefined' ? window.location.href : ''; 
        return (
            <section className="search-filter-section">
                {searchurl !== "" &&
                <Helmet>
                    <script type="application/ld+json">{`{ 
                        "@context" : "http://schema.org",
                        "@type" : "Product",
                        "name" : "${meta.title}",
                        "image" : "${searchurl}",
                        "description" : "${meta.description}",
                        "brand" : {
                        "@type" : "Organization",
                        "name" : "25 Spaces Real Estate"
                        },
                        "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.9",
                        "reviewCount": "329"
                        },
                        "offers": {
                        "@type": "AggregateOffer",
                        "lowPrice": ${this.state.minPrice},
                        "highPrice": ${this.state.maxPrice},
                        "priceCurrency": "QAR"
                        } 
                            }`}
                    </script>
        </Helmet>}

                <InstantSearch
                    searchClient={searchClient}
                    indexName={index_name}
                    searchState={searchState}
                    onSearchStateChange={this.onSearchStateChange}
                >
                    <SeoHelmet
                        metaTitle={`${meta.title} | 25 Spaces Real Estate`}
                        metaDescription={meta.description}
                    ></SeoHelmet> 
                    <Configure hitsPerPage={6} />
                    <SearchFilter />
                    <h1><span className='intro'>{introText_h1}</span></h1>
                    <p className='intro-content'>{introContent}</p>
                    <div className="search-hits-container">
                        <InfiniteHits minHitsPerPage={6} hitComponent={PropertyCard} />
                    </div>
                    <div className='search-end-content'>
                       <SearchAreaContent searchType={searchParams.search_type} {...this.props}/>
                    </div>
                </InstantSearch>
            </section>
        )
    }
}


export default SearchResultTemplate;